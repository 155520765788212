import moment from "moment";
import { Link } from "@reach/router";
import React, { useContext, useEffect, useState } from "react";
import { PlaybacksContext } from "../../context/PlaybacksContext";
import { ReservationsContext } from "../../context/ReservationsContext";
import { CustomerWorkoutPlansContext } from "../../context/CustomerWorkoutPlansContext";
import ProgressBar from "../common/ProgressBar";
import { setupTooltips } from "../../utils";
import { handleWorkoutPlanner } from "../../utils/workout_planner";
import InlineStreaks from "./InlineStreaks";

const StreakWeek = ({
  start_date,
  end_date,
  hideHeader,
  weekIndex,
  modifier,
}) => {
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [daysCompleted, setDaysCompleted] = useState(0);
  const [daysComponents, setDaysComponents] = useState(null);

  const { playbacks, getPlaybacksByDate } = useContext(PlaybacksContext);
  const { reservations, getMyReservations } = useContext(ReservationsContext);
  const { customer_workout_plan, getActiveCustomerWorkoutPlan } = useContext(
    CustomerWorkoutPlansContext
  );

  useEffect(() => {
    if (customer_workout_plan === null) {
      getActiveCustomerWorkoutPlan();
    }
    if (!Array.isArray(reservations)) {
      getMyReservations();
    }
    setupTooltips();
    handleDates();
  }, []);

  useEffect(() => {
    if (
      customer_workout_plan &&
      customer_workout_plan !== null &&
      Array.isArray(reservations) &&
      Array.isArray(playbacks)
    ) {
      handleDays();
    }
  }, [customer_workout_plan, reservations, playbacks]);

  useEffect(() => {
    if (!start_date && !end_date) {
      getPlaybacksByDate(startDate, endDate);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (typeof modifier === "function") {
      modifier(weekIndex, daysCompleted);
    }
  }, [daysCompleted]);

  const getProgressValue = (frequency) => {
    return parseInt((daysCompleted / frequency) * 100);
  };

  const getColorClassName = (frequency) => {
    if (start_date && start_date !== null) {
      if (moment(start_date).isAfter(moment().endOf("isoWeek"))) {
        return "light";
      }
    }
    if (daysCompleted >= frequency) return "success";
    if (daysCompleted + 1 === frequency) return "warning";
    return "danger";
  };

  const handleDates = () => {
    if (start_date && start_date !== null) {
      setStartDate(start_date);
    } else {
      setStartDate(moment().startOf("isoWeek").format("YYYY-MM-DD"));
    }
    if (end_date && end_date !== null) {
      setEndDate(end_date);
    } else {
      setEndDate(moment().endOf("isoWeek").format("YYYY-MM-DD"));
    }
  };

  const hasCompletedDay = (dateString) => {
    if (customer_workout_plan && customer_workout_plan !== null) {
      if (
        moment(dateString).isBefore(moment(customer_workout_plan.start_date)) ||
        moment(dateString).isAfter(moment(customer_workout_plan.end_date))
      ) {
        return false;
      }
    }
    if (Array.isArray(playbacks)) {
      const currentPlaybacks = playbacks.find(({ day }) => day === dateString);
      if (currentPlaybacks && currentPlaybacks !== null) {
        if (currentPlaybacks.playbacks > 0) return true;
      }
    }
    if (Array.isArray(reservations)) {
      const dateReservations = reservations.filter(
        ({ single_class }) =>
          moment(single_class.class_date).format("YYYY-MM-DD") === dateString
      );
      if (dateReservations.length > 0) {
        let attended = dateReservations.filter(({ attend }) => attend);
        return attended.length > 0;
      }
    }
  };

  const handleDays = () => {
    let components = [];
    let completed = 0;
    const days = moment(endDate).diff(startDate, "days");
    for (let i = 0; i < days; i++) {
      const current = moment(startDate).add(i, "days").format("YYYY-MM-DD");
      const dayComplete = hasCompletedDay(current);
      components.push(
        <div key={current} className="col-2">
          <div
            className={`card p-3 shadow bg-${
              dayComplete ? "success" : "light"
            } text-center`}
          >
            <p className="bold mb-2">{moment(current).format("DD MMM")}</p>
            {renderCheck(current)}
          </div>
        </div>
      );
      if (dayComplete) completed++;
    }
    setDaysComponents(components);
    setDaysCompleted(completed);
  };

  const renderCheck = (dateString) => {
    if (hasCompletedDay(dateString)) {
      return <i className="fa fa-check-circle text-white fa-2x" />;
    }
    return <p className="mb-4 pb-2"></p>;
  };

  const renderWeekIndex = () => {
    if (weekIndex && weekIndex !== null) {
      return `S${weekIndex} -`;
    }
  };

  const renderDays = () => {
    if (
      Array.isArray(daysComponents) &&
      customer_workout_plan &&
      customer_workout_plan !== null
    ) {
      if (moment().utc().isBefore(moment(customer_workout_plan.start_date).utc())) {
        return (
          <p>
            Prepárate, tu Workout Planner comienza el{" "}
            <span className="bold text-primary">
              {moment(customer_workout_plan.start_date).utc().format("DD MMM YYYY")}
            </span>
          </p>
        );
      }
      if (moment().utc().isAfter(moment(customer_workout_plan.end_date).utc())) {
        return (
          <p>
            Haz terminado tu último Workout Planner. Inicia uno nuevo{" "}
            <Link to="/pages/workout-planer" className="text-primary bold">aquí.</Link>
          </p>
        );
      }
      const { frequency } = customer_workout_plan.workout_plan;
      return (
        <div className="container-fluid px-0 mt-3">
          <div className="row align-items-center">
            <div className={`${hideHeader ? "col-8 col-md-10" : "col-12"}`}>
              <ProgressBar
                progress={getProgressValue(frequency)}
                color={getColorClassName(frequency)}
              />
            </div>
            {hideHeader && (
              <div className="col-4 col-md-2">{renderFrequency()}</div>
            )}
          </div>
          <div className="streak-week-container py-3">
            <div className="row streak-week mt-3">{daysComponents}</div>
          </div>
        </div>
      );
    }
    if (!hideHeader) {
      return (
        <div>
          <p>Comienza por crear un Workout Planner para rastrear tus logros.</p>
          <Link to="/pages/workout-planner" className="btn btn-primary">
            Crear un Workout Planner
          </Link>
        </div>
      );
    }
  };

  const renderFrequency = () => {
    if (customer_workout_plan && customer_workout_plan !== null) {
      const { frequency } = customer_workout_plan.workout_plan;
      return (
        <p
          className={`br-10 text-center mb-0 small bold px-2 py-1 bg-${getColorClassName(
            frequency
          )}`}
        >
          {renderWeekIndex()} {getProgressValue(frequency)}%
        </p>
      );
    }
  };

  const renderMonth = () => {
    if (customer_workout_plan && customer_workout_plan !== null) {
      return (
        <button
          onClick={() => handleWorkoutPlanner(customer_workout_plan)}
          className="btn w-100 btn-sm btn-primary"
        >
          Ver Mes
        </button>
      );
    }
  };

  return (
    <div className="container-fluid mt-2 mb-5">
      {!hideHeader && (
        <div className="row mb-3 align-items-center">
          <div className="col-12 col-md-10">
            <h2 className="h3 mb-0">
              Mi Goal Tracker Semanal <InlineStreaks />
            </h2>
            <p className="small mb-0">
              Cada día que realices una clase de TBM Online o Presencial se
              contará como un objetivo alcanzado.
            </p>
          </div>
          <div className="col-12 col-md-2 text-end">{renderMonth()}</div>
        </div>
      )}
      {renderDays()}
    </div>
  );
};

export default StreakWeek;
